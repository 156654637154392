import Cookies from 'js-cookie';

type UTMCookieParameters = {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
    utm_referral_code?: string;
};

export const getUTMCookies = (): UTMCookieParameters | undefined => {
    const utmCookies = Cookies.get('trackTitanUtmParameters');
    if (utmCookies) {
        const utmParameters = JSON.parse(utmCookies);
        return utmParameters;
    } else {
        return;
    }
};
