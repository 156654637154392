import axios from 'axios';

import { getUTMCookies } from 'src/utilities/getUTMCookies';
import { useTrackTitanStore } from 'src/zustand/store';
import Cookies from 'js-cookie';

export const UNAUTH_UUID = '00000000-0000-0000-0000-000000000000';
export const UNAUTH_EMAIL = 'unauth@titanacademy.io';
const UUID_REGEX = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

type Event = {
    eventName: string;
    uuid?: string;
    data?: any;
    email?: string;
    isServer?: boolean;
    unAuthId?: string;
};

export const sendAnalyticsEvent = async (event: Event) => {
    const isRemotion = process.env.IS_REMOTION === 'true';

    if (isRemotion) {
        return;
    }

    const { impersonationUserId } = useTrackTitanStore.getState().auth;
    const { utmData, userAgent } = useTrackTitanStore.getState().app;
    const { registerId } = useTrackTitanStore.getState().storage;
    const cookieUtmData = { utm_source: 'direct', ...utmData, ...getUTMCookies() };
    const isMobileApplication = process.env.NEXT_PUBLIC_MOBILE_APP === 'true' || process.env.MOBILE_APP === 'true';
    const rawAppPath = typeof window !== 'undefined' ? window.location.pathname : 'serverSide';
    const appPath = rawAppPath
        .replace(
            new RegExp(`${UUID_REGEX}\\/\\d{14}\\/\\d+\\/\\d+`),
            '[userId]/[sessionKey]/[lapNumber]/[segmentNumber]'
        )
        .replace(new RegExp(`${UUID_REGEX}\\/\\d{14}`), '[userId]/[sessionKey]')
        .replace(new RegExp(`setup\\/${UUID_REGEX}`), 'setup/[setupId]')
        .replace(new RegExp(`profile\\/${UUID_REGEX}`), 'profile/[userId]')
        .replace(/leaderboards\/.+/, '/leaderboards/[gameTrackCarClass]');
    // Do not send analytics if impersonating user
    if (!impersonationUserId) {
        try {
            const userId = event.uuid ?? UNAUTH_UUID;
            const eventBody = {
                ...event,
                event_name: event.eventName,
                user_id: userId,
                email: event.email ?? UNAUTH_EMAIL,
                data: {
                    ...(event?.data ?? {}),
                    ...cookieUtmData,
                    un_auth_id: event.unAuthId ? event.unAuthId : registerId,
                    device: userAgent?.type,
                    mobileApplication: isMobileApplication,
                    appPath: appPath,
                    fbc: Cookies.get('_fbc') ?? undefined,
                    fbp: Cookies.get('_fbp') ?? undefined
                }
            };
            if (event.isServer) {
                await fetch(`${process.env.NEXT_PUBLIC_VULCAN_CDN_API}/api/v1/users/${userId}/interaction`, {
                    method: 'POST',
                    body: JSON.stringify(eventBody),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
            } else {
                await axios.post(
                    `${process.env.NEXT_PUBLIC_VULCAN_CDN_API}/api/v1/users/${userId}/interaction`,
                    eventBody
                );
            }
        } catch {
            console.log(`sendAnalyticsEvent - error sending analytics event, ${event.eventName}`);
        }
    }
};
